// src/context/ReservationContext.tsx
import React, { createContext, useState, ReactNode } from 'react';

// Define the Reservation interface
export interface CheckInItem {
  userName: string;
  userId: string;
  noOfPeople: number;
}

// Define the context properties
interface CheckInItemContextProps {
  checkIns: CheckInItem[];
  setCheckIns: (checkIns: CheckInItem[]) => void
}

export const CheckInItemContext = createContext<CheckInItemContextProps | undefined>(undefined);

interface CheckInItemProviderProps {
  children: ReactNode;
}

export const CheckInItemProvider: React.FC<CheckInItemProviderProps> = ({ children }) => {
  const [checkIns, setCheckIns] = useState<CheckInItem[]>([
    // Add initial sample data as needed
  ]);

  const value = {
    checkIns,
    setCheckIns
  };

  return (
    <CheckInItemContext.Provider value={value}>
      {children}
    </CheckInItemContext.Provider>
  );
};
