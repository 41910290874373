// src/context/ReservationContext.tsx
import React, { createContext, useState, ReactNode, useContext } from 'react';
  
// Define the Reservation interface
export interface Reservation {
  status: string;
  userName: string;
  userId: string;
  noOfPeople: number;
  isEligibleForCheckIn: boolean;
}

// Define the context properties
interface ReservationContextProps {
  reservations: Reservation[];
  setReservations: (reservations: Reservation[]) => void
}

export const ReservationContext = createContext<ReservationContextProps | undefined>(undefined);

interface ReservationProviderProps {
  children: ReactNode;
}

export const ReservationProvider: React.FC<ReservationProviderProps> = ({ children }) => {
  const [reservations, setReservations] = useState<Reservation[]>([
    // Add initial sample data as needed
  ]);

  const value = {
    reservations,
    setReservations
  };

  return (
    <ReservationContext.Provider value={value}>
      {children}
    </ReservationContext.Provider>
  );
};
