import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { jwtDecode, JwtPayload } from 'jwt-decode';

export interface User {
  username?: string;
  authToken?: string;
  userType: string;
}

export interface RolesClass {
  roles: string;
}

interface AuthContextProps {
  isAuthenticated: boolean;
  user: User | undefined;
  login: (username: string, authToken: string, userType: string) => boolean;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User>();

  // Utility to check token validity
  const isTokenValid = (token: string): boolean => {
    try {
      const decoded = jwtDecode<JwtPayload>(token);
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      return decoded.exp ? decoded.exp > currentTime : false;
    } catch {
      return false;
    }
  };

  // Login Function
  const login = (username: string, authToken: string, userType: string): boolean => {
    if (isTokenValid(authToken)) {
      localStorage.setItem('authToken', authToken); // Store token in localStorage
      setIsAuthenticated(true);
      setUser({
        username,
        authToken,
        userType,
      });
      return true;
    }
    return false;
  };

  // Logout Function
  const logout = () => {
    localStorage.removeItem('authToken'); // Remove token from storage
    setIsAuthenticated(false);
    setUser(undefined);
  };

  // Restore session on app load
  useEffect(() => {
    
    const token = localStorage.getItem('authToken');
    if (token && isTokenValid(token)) {
      // If token is valid, restore user session
      const decoded = jwtDecode<RolesClass & User & JwtPayload>(token);
      setUser({
        username: decoded.sub,
        authToken: token,
        userType: decoded.roles,
      });
      setIsAuthenticated(true);
    } else if (token) {
      // If token is invalid, remove it
      localStorage.removeItem('authToken');
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
