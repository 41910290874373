import React, { useContext, useEffect, useRef, useState } from 'react';
import { lazy, Suspense } from 'react';
import merchantlogo from '../assets/merchantLogo.png';
import { AuthContext } from '../context/AuthContext';
import { ReservationContext } from '../context/ReservationContext';
import { CheckInItemContext } from '../context/CheckInContext';
import { API_GATEWAY_ENDPOINT } from '../utilities/Constants';
import { RestaurantContext } from '../context/RestaurantContext';

const Reservations = lazy(() => import('../components/Reservations'));
const CheckIn = lazy(() => import('../components/CheckIn'));
const NewTableForm = lazy(() => import('../components/NewTableForm'));
const AvailableTables = lazy(() => import('../components/AvailableTables'));

const PANEL = {
  RESERVATIONS: 'Reservations',
  CHECK_IN: 'Check-In',
  ADD_TABLE: 'Add Table',
  AVAILABLE_TABLES: 'Available Tables',
} as const;

type PanelKeys = keyof typeof PANEL;

const Merchant: React.FC = () => {
  const reserveContext = useContext(ReservationContext);
  const checkInContext = useContext(CheckInItemContext);
  const restaurantContext = useContext(RestaurantContext);

  // Inside your component
  const restaurantInfoRef = useRef(restaurantContext?.restaurant);
  useEffect(() => {
    // Update ref whenever restaurantContext.restaurant changes
    restaurantInfoRef.current = restaurantContext?.restaurant;
  }, [restaurantContext?.restaurant]);

  if (!reserveContext) {
    throw new Error('Reservations must be used within a ReservationProvider');
  }

  const { reservations } = reserveContext;
  const [loading, setLoading] = useState<boolean>(true);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);
  const [lastRefreshTime, setLastRefreshTime] = useState<string | null>(null);

  const [activePanel, setActivePanel] = useState<PanelKeys>('RESERVATIONS');
  const [menuOpen, setMenuOpen] = useState(false);

  const context = useContext(AuthContext);
  const user = context?.user;

  const handleLogout = () => {
    context?.logout();
    window.location.reload(); // Refresh the page to reset the state
  };

  useEffect(() => {
    // Fetch reservations initially
    getReservations();

    // Set an interval to fetch reservations every 5 seconds
    const interval = setInterval(() => {
      getReservations();
    }, 5000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const getReservations = async () => {
    setLoading(true); // Show loading indicator
    const restInfo = restaurantInfoRef.current; // Use the ref to get the latest value
    let overrideCheckInAll = false
    if (restInfo)
      overrideCheckInAll = !(restInfo.isWaitingEnabled)

    try {
      const response = await fetch(`${API_GATEWAY_ENDPOINT}/fetchAllReservations`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          role: 'Restaurant',
          Authorization: 'Bearer ' + user?.authToken,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch reservations.');
      }

      const responseReservationsList = (await response.json()).reservationList;

      reserveContext.setReservations(responseReservationsList);

      // Update check-in context
      const checkInsAvailable = responseReservationsList
        .filter((reserve: { isEligibleForCheckIn: any; status: string; }) => 
          reserve.status === 'Pending' && (overrideCheckInAll || reserve.isEligibleForCheckIn))
        .map((reserve: { userName: any; userId: any; noOfPeople: any; }) => ({
          userName: reserve.userName,
          userId: reserve.userId,
          noOfPeople: reserve.noOfPeople,
        }));

      checkInContext?.setCheckIns(checkInsAvailable);

    } catch (error) {
      console.error('Error fetching reservations:', error);
    } finally {
      setLoading(false); // Hide loading indicator
      setLastRefreshTime(new Date().toLocaleTimeString()); // Update last refresh time
    }
  };


  const renderPanel = (panel: PanelKeys) => {
    switch (panel) {
      case 'RESERVATIONS':
        return <Reservations
        user={user}
        lastRefreshTime={lastRefreshTime!}
        loading={loading}
        tableBodyRef={tableBodyRef}
        reservations={reservations} />;
      case 'CHECK_IN':
        return <CheckIn 
        lastRefreshTime={lastRefreshTime!}
        loading={loading}/>;
      case 'ADD_TABLE':
        return <NewTableForm user={user} />;
      case 'AVAILABLE_TABLES':
        return <AvailableTables user={user} />;
      default:
        return <Reservations 
        user={user}
        lastRefreshTime={lastRefreshTime!}
        loading={loading}
        tableBodyRef={tableBodyRef}
        reservations={reservations} />;
    }
  };

  const restInfo = restaurantContext?.restaurant
  let overrideCheckInAll
  if(restInfo)
    overrideCheckInAll = !(restInfo.isWaitingEnabled)

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-900 text-white">
      {/* Header */}
      <div className="flex items-center justify-between p-4 sm:p-0 md:px-8">
        <div className="flex items-center">
          <img src={merchantlogo} alt="Merchant Logo" className="h-12 w-12 rounded-md" />
          <h1 className="text-2xl font-bold ml-4">Our Restaurant</h1>
          {overrideCheckInAll && <div className='pl-12 text-lg text-center'>Waiting is disabled</div>}
        </div>

        {/* Logout Button */}
        <div className="flex items-center space-x-4">
          <button
            className="hidden md:block bg-gradient-to-r from-pink-500 to-orange-500 text-white font-bold rounded-md px-4 py-2 shadow-md hover:from-pink-600 hover:to-orange-600 focus:outline-none focus:ring-2 focus:ring-pink-500"
            onClick={handleLogout}
          >
            Logout
          </button>

          {/* Hamburger Menu for Mobile */}
          <button
            className="block md:hidden text-white focus:outline-none"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={menuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="absolute top-0 left-0 w-full h-screen bg-purple-800 bg-opacity-90 z-50 flex flex-col items-center justify-center space-y-6 md:hidden">
          {Object.keys(PANEL).map((key) => (
            <button
              key={key}
              className="text-2xl font-bold text-white"
              onClick={() => {
                setActivePanel(key as PanelKeys);
                setMenuOpen(false);
              }}
            >
              {PANEL[key as PanelKeys]}
            </button>
          ))}
          <button
            className="bg-gradient-to-r from-pink-500 to-orange-500 text-white font-bold rounded-md px-4 py-2 shadow-md hover:from-pink-600 hover:to-orange-600 focus:outline-none focus:ring-2 focus:ring-pink-500"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      )}

      {/* Main Content */}
      <div className="p-4 md:p-8">
        {/* Desktop Layout */}
        {window.innerWidth >= 768 && (
          <div className="hidden md:flex space-x-6">
            <div className="flex flex-col w-2/3 space-y-6">
              <Suspense fallback={<div>Loading...</div>}>
                <CheckIn 
                  lastRefreshTime={lastRefreshTime!}
                  loading={loading}/>
                <Reservations
                  user={user}
                  lastRefreshTime={lastRefreshTime!}
                  loading={loading}
                  tableBodyRef={tableBodyRef}
                  reservations={reservations} />;
              </Suspense>
            </div>
            <div className="flex flex-col w-1/3 space-y-6">
              <Suspense fallback={<div>Loading...</div>}>
                <NewTableForm />
                <AvailableTables user={user} />
              </Suspense>
            </div>
          </div>
        )}

        {/* Mobile Layout */}
        {window.innerWidth < 768 && (
          <div className="block md:hidden">
            <Suspense fallback={<div>Loading...</div>}>{renderPanel(activePanel)}</Suspense>
          </div>
        )}
      </div>
    </div>
  );
};

export default Merchant;
