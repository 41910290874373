// src/context/TableContext.tsx
import React, { createContext, useState, ReactNode } from 'react';

// Define types for the table data
export interface Table {
  tableId: string;
  minimumPeopleAllowed: number;
  maximumPeopleAllowed: number;
}

interface TableContextProps {
  availableTables: Table[];
  setAvailableTables: (tables: Table[]) => void;
  addTable: (table: Table) => void
}

export const TableContext = createContext<TableContextProps | undefined>(
  undefined
);

interface TableProviderProps {
  children: ReactNode;
}

export const TableProvider: React.FC<TableProviderProps> = ({ children }) => {
  const [availableTables, setAvailableTables] = useState<Table[]>([]);

  let addTable = (table: Table) => {
    setAvailableTables([table, ...availableTables])
  }

  const value = {
    availableTables,
    setAvailableTables,
    addTable
  };

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
};
