// src/context/ReservationContext.tsx
import React, { createContext, useState, ReactNode } from 'react';

// Define the Reservation interface
export interface Restaurant {
    restaurantName: string;
    maxRetries: Number;
    maxWaitingTimeInMinutes: number;
    subscriptionEndDate: string;
    isWaitingEnabled: boolean;
}

// Define the context properties
interface RestaurantContextProps {
  restaurant?: Restaurant;
  setRestaurant: (restaurant: Restaurant) => void;
}

export const RestaurantContext = createContext<RestaurantContextProps | undefined>(undefined);

interface RestaurantProviderProps {
  children: ReactNode;
}

export const RestaurantProvider: React.FC<RestaurantProviderProps> = ({ children }) => {
  const [restaurant, setRestaurant] = useState<Restaurant>();

  const value = {
    restaurant,
    setRestaurant
  };

  return (
    <RestaurantContext.Provider value={value}>
      {children}
    </RestaurantContext.Provider>
  );
};
