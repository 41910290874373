import React, { useState, useContext, useRef, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoopedBackground } from '../components/LoopedBackground/LoopedBackground';
import merchantlogo from '../assets/merchantLogo.png';
import { Player } from '@lottiefiles/react-lottie-player';
import loadingAnimation from '../assets/lottie-loading.json'; // Ensure this path is correct
import CheckIcon from '../assets/tick.png'; // Ensure you have a check.svg in assets or adjust accordingly
import { API_GATEWAY_ENDPOINT } from '../utilities/Constants';
import LinthImage from '../assets/Linth.png'

const UserOnboard: React.FC = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('Login must be used within an AuthProvider');
  }

  const [username, setUsername] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [tableFor, setTableFor] = useState<string>('4');
  const [loading, setLoading] = useState<boolean>(false);
  const [showOTPFields, setShowOTPFields] = useState<boolean>(false);
  const [otpVerified, setOtpVerified] = useState<boolean>(false);
  const [role, setRole] = useState<string>('Customer')

  // OTP refs and state
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const roleParam = urlParams.get('role');
    if (roleParam === 'Customer' || roleParam === 'Restaurant') {
      setRole(roleParam);
    }
  }, []);

  useEffect(() => {
    console.log('Hello');
  }, [])
  
  const handleSendOTP = async () => {
    if (role !== 'Customer' && role !== 'Restaurant') {
      toast.error('Some error has occured, please scan the QR and start over.');
      return;
    }
    if (!phone) {
      toast.error('Please enter a phone number.');
      return;
    }
    if (phone.length !== 10) {
      toast.error("Please enter a valid 10 digit mobile number")
      return
    }
    if (!username.length) {
      toast.error("Please enter a valid name.")
      return
    }
    let phoneNumberToSend = '+91' + phone
    setLoading(true);
    setShowOTPFields(false);

    try {
      const response = await fetch(`${API_GATEWAY_ENDPOINT}/sendOTP/${phoneNumberToSend}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to send OTP');
      }

      toast.success('OTP sent successfully!');
      setLoading(false);
      setShowOTPFields(true); 
    } catch (error: any) {
      toast.error('An internal error occured, please try again after sometime');
      setLoading(false);
      // Remain at initial state
    }
  };

  const handleVerifyOTP = async () => {
    if (role !== 'Customer' && role !== 'Restaurant') {
      toast.error('Some error has occured, please scan the QR and start over.');
      return;
    }
    // Gather the OTP from the 6 inputs
    const otpDigits = inputsRef.current.map(input => input?.value || '').join('');
    if (otpDigits.length < 6) {
      toast.error('Please enter the complete 6-digit OTP');
      return;
    }

    let phoneNumberToSend = '+91' + phone
    setLoading(true);

    try {
      const response = await fetch(`${API_GATEWAY_ENDPOINT}/verifyOTP/${phoneNumberToSend}/${otpDigits}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'role': role
        }
      });

      if (!response.ok) {
        throw new Error('Incorrect OTP, please try again.');
      }

      const data = await response.json(); // Parse the JSON response
      const { token } = data; // Extract the token

      if (role === 'Customer') {
        const makeReservationResponse = await fetch(`${API_GATEWAY_ENDPOINT}/makeReservation`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'role': 'Customer',
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify({
            restaurantId: '+917748041187',
            customerName: username,
            numberOfPeople: tableFor
          })
        });
  
        if (!makeReservationResponse.ok) {
          if (makeReservationResponse.status === 403) {
            toast.info('You are already in the Q!');
          } else {
            throw new Error('Incorrect OTP, please try again.');
          }
        } else {
          // If successful:
          toast.success('OTP verified successfully!');
        }
      }

      setLoading(false);
      setOtpVerified(true); // Show the check icon and message
      authContext.login(phoneNumberToSend, token, role)
    } catch (error: any) {
      toast.error('An internal error occured, please try again after sometime');
      setLoading(false);
    }
  };

  const handleOTPInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (value.length === 1 && index < 5) {
      inputsRef.current[index + 1]?.focus();
    }
  };

  const handleOTPKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const { key } = e;
    const currentInput = e.currentTarget;
    if (key === 'Backspace' && !currentInput.value && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  if (authContext.isAuthenticated && authContext.user?.userType === 'Restaurant') {
    // Redirect to main page if authenticated (if needed, adjust logic)
    return <Navigate to="/" replace />;
  }

  return (
    <div className='md:flex md:flex-row md:justify-around md:align-middle md:items-center'>
      <div>
        <LoopedBackground />
        <div className='flez-container flex flex-col p-24 max-sm:p-12 justify-center align-middle text-white'>
          <img src={merchantlogo} className='h-56 w-56 max-sm:h-36 max-sm:w-36 self-center' alt="Merchant Logo" />
          <div className='text-4xl font-bold mt-24'>
            Welcome
          </div>
          <div>
            Enter your details to get en’queued for your meal
            at our restaurant. 
          </div>

          {loading ? (
            // Show loading animation when loading is true
            <div className="flex justify-center items-center mt-8">
              <Player
                autoplay
                loop
                src={loadingAnimation}
                style={{ height: '150px', width: '150px' }}
              />
            </div>
          ) : otpVerified && role === 'Customer' ? (
            // OTP verified successfully: Show a success message and tick icon
            <div className="flex flex-col mt-8 items-center">
              <img alt='merchant logo' src={CheckIcon} className="w-16 h-16 text-green-500 mb-4"/>
              <div className="text-2xl font-semibold">Your reservation has been taken!</div>
            </div>
          ) : otpVerified && role === 'Restaurant' ? (
            // OTP verified successfully: Show a success message and tick icon
            <></>
          ) : showOTPFields ? (
            // Show OTP fields after a successful OTP send
            <div className="flex flex-col mt-8 items-center">
              <div className="text-lg mb-4">Enter the 6-digit OTP sent to {phone}</div>
              <div className="flex space-x-4 justify-center items-center">
                {Array.from({ length: 6 }).map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    ref={(el) => (inputsRef.current[index] = el)}
                    onChange={(e) => handleOTPInputChange(e, index)}
                    onKeyDown={(e) => handleOTPKeyDown(e, index)}
                    className="
                      w-12 h-12 
                      text-center text-xl 
                      bg-white/20 text-white placeholder-gray-300 
                      rounded-md shadow-sm
                      focus:outline-none focus:ring-2 focus:ring-pink-500 
                      transition duration-200 ease-in-out 
                    "
                  />
                ))}
              </div>
              <button 
                onClick={handleVerifyOTP} 
                className="w-full py-3 rounded-md text-white font-bold shadow-md mt-6
                bg-gradient-to-r from-pink-500 to-orange-500 hover:from-pink-600 hover:to-orange-600 
                transition duration-200 ease-in-out 
                focus:outline-none focus:ring-2 focus:ring-pink-500"
              >
                VERIFY
              </button>
            </div>
          ) : (
            // Show initial fields if not loading and not showing OTP fields
            <div className='mt-8 space-y-6'>
              <div>
                <label htmlFor="name" className="block mb-2 text-white font-semibold">
                  Name
                </label>
                <input 
                  type="text" 
                  id="name" 
                  name="name"
                  placeholder="Your Name"
                  className="w-full px-4 py-3 bg-white/20 text-white placeholder-gray-300 rounded-md 
                    focus:outline-none focus:ring-2 focus:ring-pink-500
                    transition duration-200 ease-in-out shadow-sm
                  "
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              
              <div>
                <label htmlFor="phone" className="block mb-2 text-white font-semibold">
                  Phone Number
                </label>
                <input 
                  type="tel" 
                  id="phone" 
                  name="phone" 
                  placeholder="9876543210"
                  className="
                    w-full px-4 py-3 
                    bg-white/20 text-white placeholder-gray-300
                    rounded-md shadow-sm
                    focus:outline-none focus:ring-2 focus:ring-pink-500 
                    transition duration-200 ease-in-out
                  "
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              {role === 'Customer' && <div>
                <label htmlFor="guests" className="block mb-2 text-white font-semibold">
                  # of Guests
                </label>
                <div className="relative">
                  <select 
                    id="guests" 
                    name="guests"
                    className="w-full px-4 py-3
                    bg-white/20 text-white
                      rounded-md shadow-sm
                      focus:outline-none focus:ring-2 focus:ring-pink-500 
                      transition duration-200 ease-in-out
                      appearance-none
                    "
                    value={tableFor}
                    onChange={(e) => setTableFor(e.target.value)}
                  >
                    <option value="1" className="bg-white text-black">1</option>
                    <option value="2" className="bg-white text-black">2</option>
                    <option value="3" className="bg-white text-black">3</option>
                    <option value="4" className="bg-white text-black">4</option>
                    <option value="5" className="bg-white text-black">5</option>
                    <option value="6" className="bg-white text-black">6</option>
                    <option value="7" className="bg-white text-black">7</option>
                    <option value="8" className="bg-white text-black">8</option>
                    <option value="9" className="bg-white text-black">9</option>
                    <option value="10" className="bg-white text-black">10</option>
                  </select>

                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <svg 
                      className="h-4 w-4 fill-current text-white" 
                      viewBox="0 0 20 20"
                    >
                      <path d="M5.23 7.21a.75.75 0 00-1.06 1.06L9.485 13.6a.75.75 0 001.06 0L15.83 8.27a.75.75 0 00-1.06-1.06L10 11.02 5.23 7.21z" />
                    </svg>
                  </div>
                </div>
              </div>}

              <button 
                onClick={handleSendOTP}
                className="w-full py-3 rounded-md text-white font-bold shadow-md mt-6
                  bg-gradient-to-r from-pink-500 to-orange-500 hover:from-pink-600 hover:to-orange-600 
                  transition duration-200 ease-in-out 
                  focus:outline-none focus:ring-2 focus:ring-pink-500
                "
              >
                SEND OTP
              </button>
            </div>
          )}

          <div className='text-center mt-4'>
            Powered by QMeNow
          </div>
        </div>
      </div>
      <div className='max-md:hidden'>
          <img src={LinthImage} alt='decorative' style={{height: '80vh'}}/>
      </div>
    </div>
  );
};

export default UserOnboard;