// src/Routes.tsx
import React, { useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Merchant from '../screens/Merchant';
import UserOnboard from '../screens/UserOnboard';
import { AuthContext } from '../context/AuthContext';
import { ToastContainer } from 'react-toastify';
import { API_GATEWAY_ENDPOINT } from '../utilities/Constants';
import { RestaurantContext } from '../context/RestaurantContext';

const AppRoutes: React.FC = () => {

  const authContext = useContext(AuthContext);
  const restaurantContext = useContext(RestaurantContext);

  useEffect(() => {
    if(authContext?.user?.userType === 'Restaurant')
      getRestaurantInfo();
  }, [authContext?.isAuthenticated]);

  const getRestaurantInfo = async () => {
    const response = await fetch(`${API_GATEWAY_ENDPOINT}/fetchRestaurantInfo`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        role: 'Restaurant',
        Authorization: 'Bearer ' + authContext?.user?.authToken,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch RestaurantInfo.');
    }

    restaurantContext?.setRestaurant((await response.json()))
  }

  return (
    <>
      {authContext?.isAuthenticated && authContext.user?.userType === 'Restaurant' ? <Merchant /> : <UserOnboard /> }
      <ToastContainer position="top-center" />
    </>
  );
};

export default AppRoutes;
