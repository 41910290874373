// src/App.tsx
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { TableProvider } from './context/TableContext';
import { ReservationProvider } from './context/ReservationContext';
import AppRoutes from './routes/Routes';
import { CheckInItemProvider } from './context/CheckInContext';
import { RestaurantProvider } from './context/RestaurantContext';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <TableProvider>
          <CheckInItemProvider>
            <ReservationProvider>
              <RestaurantProvider>
                <AppRoutes />
              </RestaurantProvider>
            </ReservationProvider>
          </CheckInItemProvider>
        </TableProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
